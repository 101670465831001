import { template as template_14dc9451fc744bdbb9879c52ab98506a } from "@ember/template-compiler";
const FKText = template_14dc9451fc744bdbb9879c52ab98506a(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
