import { template as template_253db72886c0419da70429d77810037a } from "@ember/template-compiler";
const FKLabel = template_253db72886c0419da70429d77810037a(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
